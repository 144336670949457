/* eslint-disable no-unused-expressions */
import { Skill } from '@/models/Skill';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { debounce, isEmpty, cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { AxiosError, AxiosResponse } from 'axios';
import { styles } from '@/support/Style';
import ErrorHandler from '@/support/ErrorHandler';
import { ReportStatusMap, getStatusLabel, getStatusColor, excludePlanningStatuses } from '@/support/ReportStatus';
import { formatDate } from '@/support/String';
import { Options } from '@/components/mi-dialog/MiDialog';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import { User, userRoles } from '@/models/User';
import { Tag } from '@/models/Tag';
import { Organization } from '@/models/Organization';
import { SSO } from '@/models/SSO';
import { Report } from '@/models/Report';
import { ReportType } from '@/models/ReportType';
import { ReportTypes } from '@/support/ReportTypes';
import { Address } from '@/models/Address';
import { ReportFilters } from '@/models/UserFilter';
import UserFilter from '@/components/user-filter/UserFilter';

@Component<PlannableReports>({
  filters: {
    dateFormatTime: (date: string) => {
      if (! date) { return ''; }
      return formatDate(date, 'dd-LL-yyyy HH:mm');
    },
  },
})
export default class PlannableReports extends Vue {
  public $pageTitle = 'Dossiers';

  protected filters: object = {};

  protected userFilters: UserFilter[] | null = null;

  protected selectedUserFilter: UserFilter | null = null;

  protected tableSearchInput = '';

  protected reports: Report[] = [];

  protected organizations: Organization[] = [];

  protected experts: User[] = [];

  protected tags: Tag[] = [];

  protected reportTypes: ReportType[] = [];

  protected reportHandlers: User[] = [];

  protected report: Report | null = null;

  protected reportStatusMap: ReportStatusMap[] = excludePlanningStatuses();

  protected hideDeadlineColumn: string[] = ['created', 'planning', 'downloaded', 'aborted', 'objection_closed', 'smr_closed', 'smr_created', 'objected_created'];

  protected expertSearchInput = '';

  protected reportHandlerSearchInput = '';

  protected reportCreated = false;

  protected selectedFilterName = 'Mijn filters';

  protected isCreatingFilter = false;

  protected filterDefault = false;

  protected selectedFilter: ReportFilters = {};

  protected isFiltersMenuOpen = false;

  protected tableFilters: ReportFilters = {};

  protected isShowingFilters = false;

  protected filtersHeight = 0;

  protected isLoading = false;

  protected caseNumber = '';

  protected reportType = '';

  protected hideFutureReports = false;

  protected isLoadingDatatable = true;

  public searchDebounce: Function = this.handleExpertSearch();

  public searchReportHandlerDebounce: Function = this.handleReportHandlerSearch();

  protected userRoles = userRoles;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  }

  protected deadlineIcons: any = {
    // eslint-disable-next-line global-require
    approved: require('@/assets/images/icons/request-accepted.svg'),
    pending: require('@/assets/images/icons/request-pending.svg'),
    rejected: require('@/assets/images/icons/request-rejected.svg'),
  };

  protected tableFilterMap: {[key: string]: any} = {
    ims: {
      types: [
        ReportTypes.IMMATERIELESCHADE,
      ],
    },
    ves: {
      types: [
        ReportTypes.VES_NULMETING,
      ],
    },
    default: {
      exclude_types: [
        ReportTypes.IMMATERIELESCHADE,
        ReportTypes.VES_NULMETING,
      ],
    },
    exclude_ves: {
      exclude_type: ReportTypes.VES_NULMETING,
    },
  }

  protected get queryPlanningStatus() {
    return this.$route.query.planning_status;
  }

  protected get queryStatus() {
    return this.$route.query.status;
  }

  public logout() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  public mounted() {
    console.log('PlannableReports mounted');
    this.initialize();
  }

  protected async initialize() {
    this.emitBreadcrumb();
    this.isLoadingDatatable = true;

    await Promise.all([
      this.getExperts(),
      this.getTags(),
      this.getReportTypes(),
      this.getReportHandlers(),
      this.getOrganizations(),
    ]);

    this.fetchUrlFilters();

    this.isLoadingDatatable = false;
  }

  protected fetchUrlFilters() {
    const filterKey = this.$route.query.filter;
    const tableFilterMap = cloneDeep(this.tableFilterMap[filterKey as string]);
    this.tableFilters = { ...tableFilterMap, ...this.tableFilters };
  }

  protected get tableName(): string {
    let currentStatus = this.queryPlanningStatus;
    if (! currentStatus) {
      currentStatus = 'Alle schades';
    }

    return currentStatus === 'Alle schades' ? currentStatus : getStatusLabel((currentStatus as string));
  }

  protected async getReportHandlers(): Promise<void> {
    this.reportHandlers = await new User()
      .filter('search', this.reportHandlerSearchInput)
      .sort('name', 'ASC')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async getExperts(): Promise<void> {
    this.experts = await new User()
      .filter('search', this.expertSearchInput)
      .limit(500)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async getOrganizations(): Promise<void> {
    this.organizations = await new Organization()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async getReportTypes(): Promise<void> {
    await new ReportType()
      .all()
      .then((reportTypes: ReportType[]) => {
        this.reportTypes = reportTypes;
        const appealType = reportTypes.find((reportType: any) => reportType.uuid === ReportTypes.ADVIESRAPPORT_BEZWAAR);
        if (appealType && appealType.uuid) {
          this.reportType = this.$store.state.Auth.hasRole('appeals_committee') ? appealType.uuid : '';
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getTags(): Promise<void> {
    this.tags = await new Tag()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    this.tags.push({
      name: 'Geen Tags',
      id: 'no_tags',
    } as any);
  }

  private get dataTableFilters() {
    return this.tableFilters;
  }

  protected get tableOptions(): Object {
    return {
      model: new Report()
        .include('organization')
        .sort('planning', 'ASC')
        .filter(this.dataTableFilters),
      name: {
        singular: this.queryPlanningStatus ? getStatusLabel((this.queryPlanningStatus as string)) : 'Planning Werkvoorraad',
        plural: this.queryPlanningStatus ? getStatusLabel((this.queryPlanningStatus as string)) : 'Planning Werkvoorraad',
      },
      headers: [
        {
          text: 'Zaaknummer',
          value: 'case_number',
          transform: (case_number: any, report: any) => {
            if (! case_number) {
              return '';
            }

            if (report.is_prio) {
              return `<i aria-hidden="true" class="v-icon material-icons theme--light isPrio">warning</i> ${case_number}`;
            }

            return case_number;
          },
          action: (report: Report) => {
            if (report?.type?.uuid === ReportTypes.VES_NULMETING) {
              this.$router.push(`/planning-tool-ves-nulmeting?report=${report.uuid}`);
              return;
            }
            if (report.planning_status && report.planning_status === 'not_needed_anymore') {
              this.$router.push(`/reports/${report.uuid}`);
              return;
            }

            this.$router.push(`/planning-tool?report=${report.uuid}`);
          },
          sortable: {
            key: 'case_number',
            order: 'ASC',
          },
          width: '10%',
        },
        {
          text: 'Type rapport',
          value: 'type',
          transform: (report_type: any) => report_type.name,
          sortable: {
            key: 'type',
            order: 'ASC',
          },
          width: '10%',
        },
        {
          text: 'Adres',
          value: 'address',
          transform: (address: any) => this.parseAddress(address),
        },
        {
          text: 'Volgende actie',
          value: 'planning_next_action_at',
          width: '10%',
          transform: (nextActionDate: string) => {
            if (! nextActionDate || nextActionDate === '0000-00-00 00:00:00') {
              return '-';
            }

            return formatDate(nextActionDate, 'dd-LL-yyyy');
          },
          sortable: {
            key: 'planning_next_action_at',
            order: 'DESC',
          },
        },
        {
          text: 'Laatste actie',
          value: 'planning_last_action_at',
          width: '10%',
          transform: (lastActionDate: string) => {
            if (! lastActionDate || lastActionDate === '0000-00-00 00:00:00') {
              return '-';
            }

            return formatDate(lastActionDate, 'dd-LL-yyyy');
          },
          sortable: {
            key: 'planning_last_action_at',
            order: 'ASC',
          },
        },
        {
          text: 'Indieningsdatum',
          value: 'applicant_submitted_at',
          transform: (applicant_submitted_at: any) => {
            if (applicant_submitted_at === '0000-00-00') {
              return '';
            }
            return formatDate(applicant_submitted_at, 'dd-LL-yyyy');
          },
          sortable: {
            key: 'applicant_submitted_at',
            order: 'DESC',
          },
          width: '10%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '10%',
          transform: (status: string) => {
            if (! status) {
              return '';
            }
            return `<span class="statusPill statusBackgroundColor--${getStatusColor(status)}">${getStatusLabel(status)}</span>`;
          },
          sortable: {
            key: 'status',
            order: 'ASC',
          },
        },
        {
          text: 'Tags',
          value: 'tags',
          transform: (tags: Tag[]) => {
            if (! tags) {
              return '';
            }

            let tagNames = '';
            tags.forEach((tag: Tag, index: number) => {
              index == 0 ? tagNames += `${tag.name}` : tagNames += `, ${tag.name}`;
            });
            return tagNames;
          },
        },
        {
          text: 'Competenties',
          value: 'skills',
          transform: (skills: Skill[]) => {
            if (! skills) {
              return '';
            }

            let skillNames = '';
            skills.forEach((skill: Skill, index: number) => {
              index == 0 ? skillNames += `${skill.name}` : skillNames += `, ${skill.name}`;
            });
            return skillNames;
          },
        },
      ],
      filter: [],
    };
  }

  get dialogOptions(): Options {
    return {
      title: 'Rapport verwijderen',
      text: "Weet u zeker dat u dit rapport wilt verwijderen? Alle data (en foto's) worden dan verwijderd.",
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: () => {
            if (! this.report) {
              return;
            }

            this.report
              .delete()
              .then((response: AxiosResponse) => {
                if (response) {
                  (this.$refs.reportDatatable as DataTable).refresh();
                }
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.report = null;
          },
        },
      },
    };
  }

  protected parseDeadline(deadline_at: string, report: Report) {
    if (deadline_at === '0000-00-00 00:00:00') {
      return '';
    }

    const deadlineDate = DateTime.fromFormat(deadline_at, 'yyyy-LL-dd HH:mm:ss');
    const currentDate = DateTime.local();
    const difference = deadlineDate.diff(currentDate, 'days').toObject().days;
    const deadlineNumber = Math.floor((difference as any));

    let deadlineIcon = '';
    let deadlineNumberClass = '';
    let deadlineNumberType = '';

    if (report && report.last_late_request_status) {
      deadlineIcon = `<span><img class="deadlineIcon" src="${this.deadlineIcons[report.last_late_request_status]}"></span>`;
    }

    if (deadlineNumber < 0) {
      deadlineNumberClass = 'negativeNumber';
    } else if (deadlineNumber >= 0 && deadlineNumber < 5) {
      deadlineNumberClass = 'warningNumber';
      deadlineNumberType = '+';
    } else {
      deadlineNumberClass = 'positiveNumber';
      deadlineNumberType = '+';
    }

    return `<span class="deadlineNumber ${deadlineNumberClass}">${deadlineNumberType}${deadlineNumber}</span> ${deadlineIcon}`;
  }

  protected parseAddress(address: Address) {
    if (! address) {
      return '';
    }

    if (! address.street && ! address.number && ! address.number_add && ! address.postcode && ! address.city) {
      return '';
    }

    return `${address.street} ${address.number} ${address.number_add} <br>
            ${address.postcode} ${address.city}`;
  }

  protected handleConfirm() {
    if (! this.isValidNewReport) {
      return;
    }

    this.isLoading = true;

    const payload: {[key:string]: string} = {
      case_number: this.caseNumber,
      report_type: this.$store.state.Auth.hasRole('appeals_committee') ? ReportTypes.ADVIESRAPPORT_BEZWAAR : this.reportType,
    };

    if (payload.report_type === ReportTypes.ADVIESRAPPORT_BEZWAAR) {
      (payload as any).status = 'objection_created';
    }

    if (payload.report_type === ReportTypes.STUWMEERREGELING) {
      (payload as any).status = 'smr_created';
    }

    new Report()
      .create(payload)
      .then((report: Report) => {
        this.isLoading = false;
        this.reportCreated = true;
        this.$router.push(`/reports/${report.uuid}`);
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  protected get isValidNewReport(): boolean {
    if ((this.caseNumber && this.caseNumber.length) && (this.reportType && this.reportType.length)) {
      return true;
    }
    return false;
  }

  protected getColor(key: string): string {
    return styles[key];
  }

  protected getSSOLink(caseNumber: string) {
    new SSO()
      .create({ case_number: caseNumber })
      .then((sso: SSO) => {
        if (sso && sso.app_link) {
          window.location.href = sso.app_link;
        }
      })
      .catch(() => {});
  }

  protected get showExpertFilter() {
    return ! this.$store.state.isServiceOrganization && ! this.$store.state.Auth.hasRole('expert');
  }

  protected get workPreparation() {
    if (! this.$store.state.Auth) {
      return false;
    }

    const hasCorrectLevel = this.$store.state.Auth.hasRole([...this.userRoles.WVRoles, ...this.userRoles.PlanningRoles]);
    const isWorkPreperation = this.$route.fullPath.includes('?status=created') || this.$route.fullPath.includes('?status=smr_created');
    if (hasCorrectLevel && isWorkPreperation) {
      return true;
    }

    const hasCorrectLevelAppealsCommittee = this.$store.state.Auth.hasRole(this.userRoles.ObjectionRoles);
    const isWorkPreperationAppealsCommittee = this.$route.fullPath.includes('?status=objection_created');

    return hasCorrectLevelAppealsCommittee && isWorkPreperationAppealsCommittee;
  }

  protected get projectDatatable(): DataTable|null {
    const dataTable = this.$refs.projectDatatable;
    if (dataTable) {
      return (dataTable as DataTable);
    }

    return null;
  }

  protected closeCreatingFilter() {
    this.isCreatingFilter = false;

    const textField = this.$refs.filterName;
    // eslint-disable-next-line no-sequences
    (textField as any).$refs.input.value = '',
    this.filterDefault = false;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: this.queryPlanningStatus ? getStatusLabel((this.queryPlanningStatus as string)) : 'Planning Werkvoorraad' },
        ],
      });
  }

  // USER FILTERS
  protected beforeDestroy() {
    if (this.tableFilters.planning_status) {
      delete this.tableFilters.planning_status;
    }

    if (this.tableFilters.planning) {
      delete this.tableFilters.planning;
    }

    if (this.tableFilters.plannable_action) {
      delete this.tableFilters.plannable_action;
    }

    this.$store.dispatch('changeLastUsedReportFilter', { ...this.dataTableFilters, ...{ user_filter_type: this.queryStatus || 'reports' } });
    this.$store.dispatch('changeLastUsedReportFilter', this.dataTableFilters);
  }

  protected handleApplyFilter(selectedUserFilters: ReportFilters) {
    this.tableFilters = cloneDeep(selectedUserFilters);
    this.tableSearchInput = selectedUserFilters.search ? cloneDeep(selectedUserFilters.search) : '';
  }

  protected resetFilters() {
    (this.$refs.userFilter as UserFilter).resetFilterName();
    this.tableFilters = {};
    this.tableSearchInput = '';
    if (this.queryPlanningStatus) {
      this.tableFilters.planning_status = this.queryPlanningStatus as string;
    } else {
      this.tableFilters.planning_status = '';
    }

    if (this.queryStatus) {
      this.tableFilters.status = this.queryStatus as string;
    } else {
      this.tableFilters.status = '';
    }
  }

  protected get filterPayload() {
    if (this.$refs.reportDatatable) {
      this.tableFilters.search = (this.$refs.reportDatatable as DataTable).searchInputValue;
    }

    if (this.queryPlanningStatus) {
      this.tableFilters.planning_status = this.queryPlanningStatus as string;
    } else {
      this.tableFilters.planning_status = '';
    }

    if (this.queryStatus) {
      this.tableFilters.status = this.queryStatus as string;
    } else {
      this.tableFilters.status = '';
    }

    return this.tableFilters;
  }

  protected get hasFilters(): boolean {
    let hasFilters = false;

    Object.keys(this.tableFilters).forEach((tableFilterKey: string) => {
      if (tableFilterKey) {
        if (tableFilterKey === 'plannable_action' && (this.tableFilters as any)[tableFilterKey] === true) {
          hasFilters = true;
        } else if (! isEmpty((this.tableFilters as any)[tableFilterKey]) && tableFilterKey !== 'planning_status' && tableFilterKey !== 'planning') {
          hasFilters = true;
        }
      }
    });

    return hasFilters;
  }

  protected resetFilterName() {
    if ((this.$refs.userFilter)) {
      (this.$refs.userFilter as UserFilter).resetFilterName();
    }
  }

  protected showFilters() {
    if (! this.isShowingFilters) {
      this.isShowingFilters = true;
      const innerFilters = document.getElementsByClassName('inner-filters');
      this.filtersHeight = innerFilters[0].clientHeight;
    } else {
      this.isShowingFilters = false;
      this.filtersHeight = 0;
    }
  }
  // END USER FILTERS

  get user(): User {
    return this.$store.state.Auth;
  }

  handleReportHandlerSearch() {
    return debounce(() => {
      this.getReportHandlers();
    }, 400);
  }

  handleExpertSearch() {
    return debounce(() => {
      this.getExperts();
    }, 400);
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
    this.emitBreadcrumb();
  }

  @Watch('reportHandlerSearchInput')
  public reportHandlerSearchInputChanged(val: any) {
    if (! this.reportHandlerSearchInput) {
      this.getExperts();
    }
  }

  @Watch('hideFutureReports')
  protected hideFutureReportsChanged() {
    const filters = { ...{}, ...this.tableFilters };

    if (this.hideFutureReports) {
      filters.plannable_action = DateTime.local().toFormat('yyyy-LL-dd HH:mm:ss');
    } else {
      delete filters.plannable_action;
    }

    this.$set(this, 'tableFilters', filters);
  }

  @Watch('expertSearchInput')
  public expertSearchInputChanged(val: any) {
    if (! this.expertSearchInput) {
      this.getExperts();
    }
  }
}
