var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v(_vm._s(_vm.tableName))
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel" },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      wrap: "",
                      "align-end": "",
                      "justify-space-between": "",
                      "mb-3": ""
                    }
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "MiButton",
                          {
                            staticClass: "filterButton mr-1",
                            attrs: {
                              outline: !_vm.isShowingFilters,
                              small: "true",
                              compact: "true",
                              icon: "filter_list"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.showFilters()
                              }
                            }
                          },
                          [_vm._v(" Filters ")]
                        ),
                        _vm.hasFilters
                          ? _c(
                              "MiButton",
                              {
                                staticClass: "filterButton ml-1",
                                attrs: {
                                  color: "secondary",
                                  small: "true",
                                  compact: "true",
                                  icon: "autorenew"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.resetFilters($event)
                                  }
                                }
                              },
                              [_vm._v(" Reset filters ")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass: "custom-filter--userFilterReports",
                        attrs: { shrink: "" }
                      },
                      [
                        _c("UserFilter", {
                          ref: "userFilter",
                          attrs: {
                            filterType: _vm.queryStatus
                              ? _vm.queryStatus
                              : "reports",
                            filters: _vm.filterPayload
                          },
                          on: {
                            selectedUserFilters: _vm.handleApplyFilter,
                            resetFilters: _vm.resetFilters
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  {
                    staticClass: "all-filters",
                    style: "height: " + _vm.filtersHeight + "px",
                    attrs: { "align-center": "", row: "", wrap: "", "mb-3": "" }
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        staticClass: "inner-filters",
                        attrs: { xs12: "", "pt-0": "" }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              "align-center": "",
                              row: "",
                              wrap: "",
                              "ma-0": ""
                            }
                          },
                          [
                            _vm.showExpertFilter
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "with-full-border",
                                      attrs: {
                                        items: _vm.experts,
                                        "item-text": "name",
                                        "item-value": "uuid",
                                        "hide-details": "",
                                        placeholder: "Opnemer",
                                        "search-input": _vm.expertSearchInput,
                                        clearable: "",
                                        multiple: ""
                                      },
                                      on: {
                                        "update:searchInput": function($event) {
                                          _vm.expertSearchInput = $event
                                        },
                                        "update:search-input": function(
                                          $event
                                        ) {
                                          _vm.expertSearchInput = $event
                                        },
                                        keyup: this.searchDebounce,
                                        change: _vm.resetFilterName
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function(ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                index === 0
                                                  ? [
                                                      _c("span", [
                                                        _vm._v("Opnemer ")
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey--text caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "(+" +
                                                              _vm._s(
                                                                _vm.tableFilters
                                                                  .experts
                                                                  .length
                                                              ) +
                                                              ")"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4106556423
                                      ),
                                      model: {
                                        value: _vm.tableFilters.experts,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "experts",
                                            $$v
                                          )
                                        },
                                        expression: "tableFilters.experts"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$store.state.isServiceOrganization
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "with-full-border",
                                      attrs: {
                                        items: _vm.organizations,
                                        "item-text": "name",
                                        "item-value": "id",
                                        "hide-details": "",
                                        placeholder: "Bureaus",
                                        clearable: "",
                                        multiple: ""
                                      },
                                      on: { change: _vm.resetFilterName },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function(ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                index === 0
                                                  ? [
                                                      _c("span", [
                                                        _vm._v("Bureaus ")
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey--text caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "(+" +
                                                              _vm._s(
                                                                _vm.tableFilters
                                                                  .organizations
                                                                  .length
                                                              ) +
                                                              ")"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3227668731
                                      ),
                                      model: {
                                        value: _vm.tableFilters.organizations,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "organizations",
                                            $$v
                                          )
                                        },
                                        expression: "tableFilters.organizations"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$store.state.isServiceOrganization
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "custom-filter custom-filter--autocomplete",
                                    attrs: { shrink: "" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "with-full-border",
                                      attrs: {
                                        items: _vm.reportHandlers,
                                        "item-text": "name",
                                        "item-value": "uuid",
                                        "hide-details": "",
                                        placeholder: "Dossierbeheerders",
                                        "search-input":
                                          _vm.reportHandlerSearchInput,
                                        clearable: "",
                                        multiple: ""
                                      },
                                      on: {
                                        "update:searchInput": function($event) {
                                          _vm.reportHandlerSearchInput = $event
                                        },
                                        "update:search-input": function(
                                          $event
                                        ) {
                                          _vm.reportHandlerSearchInput = $event
                                        },
                                        keyup: this.searchReportHandlerDebounce,
                                        change: _vm.resetFilterName
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function(ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                index === 0
                                                  ? [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Dossierbeheerders "
                                                        )
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey--text caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "(+" +
                                                              _vm._s(
                                                                _vm.tableFilters
                                                                  .report_handlers
                                                                  .length
                                                              ) +
                                                              ")"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4279124390
                                      ),
                                      model: {
                                        value: _vm.tableFilters.report_handlers,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.tableFilters,
                                            "report_handlers",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "tableFilters.report_handlers"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-flex",
                              {
                                staticClass:
                                  "custom-filter custom-filter--autocomplete",
                                attrs: { shrink: "" }
                              },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "with-full-border",
                                  attrs: {
                                    items: _vm.reportTypes,
                                    "item-text": "name",
                                    "item-value": "uuid",
                                    "hide-details": "",
                                    placeholder: "Type",
                                    clearable: "",
                                    multiple: ""
                                  },
                                  on: { change: _vm.resetFilterName },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          index === 0
                                            ? [
                                                _c("span", [_vm._v("Type ")]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(+" +
                                                        _vm._s(
                                                          _vm.tableFilters.types
                                                            .length
                                                        ) +
                                                        ")"
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.tableFilters.types,
                                    callback: function($$v) {
                                      _vm.$set(_vm.tableFilters, "types", $$v)
                                    },
                                    expression: "tableFilters.types"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass:
                                  "custom-filter custom-filter--autocomplete",
                                attrs: { shrink: "" }
                              },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "with-full-border",
                                  attrs: {
                                    items: _vm.tags,
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-details": "",
                                    placeholder: "Tags",
                                    clearable: "",
                                    multiple: ""
                                  },
                                  on: { change: _vm.resetFilterName },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          index === 0
                                            ? [
                                                _c("span", [_vm._v("Tags ")]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(+" +
                                                        _vm._s(
                                                          _vm.tableFilters.tags
                                                            .length
                                                        ) +
                                                        ")"
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.tableFilters.tags,
                                    callback: function($$v) {
                                      _vm.$set(_vm.tableFilters, "tags", $$v)
                                    },
                                    expression: "tableFilters.tags"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass:
                                  "custom-filter custom-filter--autocomplete",
                                attrs: { shrink: "" }
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "with-full-border",
                                  attrs: {
                                    label: "Verberg toekomstige dossiers",
                                    color: _vm.getColor("secondary"),
                                    "hide-details": ""
                                  },
                                  on: { change: _vm.resetFilterName },
                                  model: {
                                    value: _vm.hideFutureReports,
                                    callback: function($$v) {
                                      _vm.hideFutureReports = $$v
                                    },
                                    expression: "hideFutureReports"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isLoadingDatatable
                  ? _c("LoaderCard", {
                      attrs: {
                        flat: "",
                        type: "spinner--center",
                        minHeight: "250px"
                      }
                    })
                  : _vm._e(),
                !_vm.isLoadingDatatable
                  ? _c("DataTable", {
                      ref: "reportDatatable",
                      attrs: {
                        options: _vm.tableOptions,
                        customSearchInput: _vm.tableSearchInput,
                        visibility: _vm.visibility
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }